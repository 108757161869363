/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKAlert from "@mui/material/Alert"

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/examples/blog2.jpg";
import emailjs from '@emailjs/browser'
import { useForm } from 'react-hook-form';
import { useState } from "react";
// import { useRef } from "react";

emailjs.init("-CDg7lD-MTT44Dy0w");

function Contact() {
  const { register, handleSubmit, setValue, watch } = useForm();
  const [alertMessage, setAlertMessage] = useState(null);
  const isValidEmail = (email) => {
    // Regular expression for a basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email)
  };
  const onSubmit = (data) => {
    // Manually set form values before submission
    if (!isValidEmail(data.email)) {
      setAlertMessage({ type: 'error', text: 'Invalid email address' });
      return;
    }
    // Use Email.js to send the email
    emailjs.send('service_bgsj5vq', 'template_prezsym', data)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setAlertMessage({ type: 'success', text: 'Email sent successfully!' });
        const delayTime = 5000;
        setTimeout(() => {
          window.location.reload();
        }, delayTime);
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
        setAlertMessage({ type: 'error', text: 'Failed to send email. Please try again later.' });
      });
  };
  return (
    <>
    {alertMessage && (
      <MKAlert severity={alertMessage.type} onClose={() => setAlertMessage(null)} sx={{position: "absolute", top: 0, left: 1 , width:"80%", marginLeft: "10%", marginRight: "10%", zIndex:9999999 }}>
        {alertMessage.text}
      </MKAlert>
    )}
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >     
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                  
                    <MKTypography variant="h3" color="white" mb={1}>
                      Contact!
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Fill out the form and our team will get back to you.
                    </MKTypography>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        support@14fourtech.com
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Denver, Colorado, U.S.A
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={3}>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post" onSubmit={handleSubmit(onSubmit)}>
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Say Hi!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      We&apos;d like to talk with you.
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          {...register('name')}
                          variant="standard"
                          label="My name is"
                          placeholder="Full Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={watch('name')} // Use watch to get the current value
                          onChange={(e) => setValue('name', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          {...register('email')}
                          variant="standard"
                          label="My email is"
                          placeholder="business@business.com"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={watch('email')} // Use watch to get the current value
                          onChange={(e) => setValue('email', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          {...register('message')}
                          variant="standard"
                          label="Details"
                          placeholder="I need help with..."
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                          value={watch('message')} // Use watch to get the current value
                          onChange={(e) => setValue('message', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton type="submit" variant="gradient" color="secondary">
                        Send Message
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
    </>
  );
}

export default Contact;
