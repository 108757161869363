/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import infoImage from "assets/images/ailabeled2.jpg";
// import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Contact from "pages/LandingPages/Author/sections/Contact";

function Information() {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="World wide service"
                    description="Delivering tech solutions that transcend geographical boundaries and adapt to your needs, no matter where you are."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Affordable solutions"
                    description="We make tech services accessible to businesses of all sizes, including non-profits, providing affordable solutions tailored to your needs."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Automation and AI"
                    description="We offer top-notch Automation and AI services, leveraging cutting-edge technology to boost your efficiency and productivity."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Ready to Connect?"
                    description="Fill out the form on our contact page and we will put you in touch with one of our developers."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <div onClick={toggleModal}>
              <CenteredBlogCard
                image={infoImage}
                title="Step into the future."
                description="Website visitors today demand a frictionless user expericence."
                action={{
                  type: "internal",
                  route: "/sections/navigation/navbars",
                  color: "",
                  label: "find out more",
                }}
              />
            </div>
            <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
              <div style={{ overflow: "scroll", height: "100%" }}>
                <Contact />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
