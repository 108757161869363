/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/integrity (1).jpg";
import team2 from "assets/images/learning2.jpg";
import team3 from "assets/images/integrity2 (1).jpg";
import team4 from "assets/images/maze.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Core Values
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              These are the values that hold 14Four together and ensure quality service for your company.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Integrity"
                position={{ color: "info", label: "" }}
                description="Integrity is at the core of 14Four, fostering trust, transparency, and ethical excellence in everything we do."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Life Long Learning"
                position={{ color: "info", label: "" }}
                description="Championing continuous growth, 14Four thrives on lifelong learning for ongoing innovation and adaptability."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Collaboration"
                position={{ color: "info", label: "" }}
                description="Collaboration fuels 14Four's success, uniting diverse talents to drive innovation and deliver exceptional results for our clients."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Finding a Way"
                position={{ color: "info", label: "" }}
                description="At 14Four, we navigate challenges with a relentless commitment to 'finding a way,' unlocking solutions through creativity and perseverance."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
