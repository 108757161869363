import React from "react";
// import Contact from "pages/LandingPages/Author/sections/Contact";
import HeaderTwo from "layouts/sections/page-sections/page-headers/components/HeaderOne/headerTwo/headerTwo";
import MKBox from "components/MKBox";
import bgImage from "assets/images/codebg.jpg";
import Information from "pages/Presentation/sections/Information";
import Card from "@mui/material/Card";
import Team from "pages/LandingPages/AboutUs/sections/Team";

function AboutPage() {
  return (
    <>
      <div style={{ height: "40rem" }}>
        <HeaderTwo />
      </div>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
      </Card>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Team />
      </Card>
      <div style={{ marginTop: "auto" }}>
        <MKBox
          minHeight="100%"
          sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.9),
                rgba(gradients.dark.state, 0.9)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></MKBox>
      </div>
    </>
  );
}

export default AboutPage;
