/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/ailabeled1.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Contact from "pages/LandingPages/Author/sections/Contact";

function HeaderOne() {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const [showNavMobile, setshowNavMobile] = useState(false);
  const toggleShowNavMobile = () => setshowNavMobile(!showNavMobile);
  return (
    <MKBox component="header" position="relative" height="100%">
      <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
        <Container>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography
              component={Link}
              href="#"
              variant="button"
              color="white"
              fontWeight="regular"
              py={1.8125}
              mr={2}
            >
              <Link to="/" style={{ fontSize: "22px", color: "#eeeeee" }}>
                14Four Tech
              </Link>
            </MKTypography>
            <MKButton
              variant="outlined"
              color="white"
              sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}
              onClick={toggleShowNavMobile}
            >
              <MKBox component="i" color="white" className="fas fa-bars" />
            </MKButton>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={4}
                  onClick={(e) => e.preventDefault()}
                >
                  <div style={{ paddingRight: "3rem" }}>
                    <Link to="/" style={{ fontSize: "22px", color: "#eeeeee" }}>
                      Home
                    </Link>
                  </div>
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={4}
                  onClick={(e) => e.preventDefault()}
                >
                  <div style={{ paddingRight: "3rem" }}>
                    <Link to="/about" style={{ fontSize: "22px", color: "#eeeeee" }}>
                      About Us
                    </Link>
                  </div>
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={4}
                  onClick={(e) => e.preventDefault()}
                >
                  <div onClick={toggleModal} style={{ fontSize: "22px", color: "#eeeeee" }}>
                    Contact Us
                  </div>
                </MKTypography>
              </MKBox>
            </MKBox>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                  fontSize="20px"
                >
                  &#x2800;
                  {/* <MKBox component="i" color="white" className="fab fa-instagram" /> */}
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                  fontSize="20px"
                >
                  &#x2800;
                  {/* <MKBox component="i" color="white" className="fab fa-instagram" /> */}
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                  fontSize="20px"
                >
                  &#x2800;
                  {/* <MKBox component="i" color="white" className="fab fa-instagram" /> */}
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
        <Modal open={showNavMobile} onClose={toggleShowNavMobile}>
        <div style={{ background: "#eeeeee", position: "absolute", top: 0, width: "96%", marginTop: "1rem", marginLeft: "2rem", marginRight: "2rem", justifyContent: "center", borderRadius: "5px"}}>
          <Link to="/">
            <div style={{ padding: "1rem", textAlign: "center", width:"100%", background: "#342E37", color: "#fcfcfc"}}>
              Home
            </div>
          </Link>
          <Link to="/about">
            <div style={{ padding: "1rem", textAlign: "center", width:"100%", background: "#555358", color: "#fcfcfc"}}>
              About Us
            </div>
          </Link>
          <div onClick={toggleModal} style={{ padding: "1rem", textAlign: "center", width:"100%", background: "#FAFFFD", color: "#2b2b2b"}}>
            Contact Us
          </div>
        </div>
        </Modal>
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} md={7} lg={6} flexDirection="column" justifyContent="center">
            <MKTypography
              variant="h1"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              14FOUR
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} pr={6} mr={6}>
              Harnessing the power of AI to craft innovative technology solutions that drive growth.
            </MKTypography>
            <Stack direction="row" spacing={1} mt={3}>
              <MKButton color="white" onClick={toggleModal}>
                Connect with us
              </MKButton>
              <Link to="/about">
                <MKButton variant="text" color="white">
                  Read more
                </MKButton>
              </Link>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center", padding: "2rem 5rem 0" }}>
        <div
          style={{
            overflow: "scroll",
            height: "100%",
          }}
        >
          <Contact />
        </div>
      </Modal>
    </MKBox>
  );
}

export default HeaderOne;
