/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// import RotatingCard from "examples/Cards/RotatingCard";
// import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
// import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// // Images
// import bgFront from "assets/images/rotating-card-bg-front.jpeg";
// import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          {/* <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Feel the
                    <br />
                    Material Kit
                  </>
                }
                description="All the MUI components that you need in a development have been re-design with the new look."
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="You will save a lot of time going from prototyping to full-functional code because all elements are implemented."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "start with header",
                }}
              />
            </RotatingCard>
          </Grid> */}
          <Grid container>
            <Grid item>
              <DefaultInfoCard
                icon="content_copy"
                title="Our mission"
                description=""
              />
              <MKTypography>
              14Four is a company that emerged from a passion for learning and programming. Inspired by Colorado&apos;s majestic mountain ranges, the name 14Four, representing the height of the tallest peaks (14,400 feet), was conceived. from humble beginnings of a single employee and a signle client, over time, we have evolved into a thriving business that serves clients globally.
              <br /> <br />
              our intention has always been to provide affordable, creative technology solutions to businesses and non-profits. From the start, 14Four focused on exceeding client expectations, demonstrating an unwavering commitment to quality and innovation. These collaborations have allowed 14Four to establish itself as a reliable and effective partner in the digital landscape.
              <br /> <br />
              At the heart of 14Four&apos;s operations lies a team of talented individuals, with a passion for helping your company to be the best company it can be. Their dedication and expertise enable them to handle a wide range of projects, no matter how challenging or innovative. It&apos;s not just about reaching the highest peaks at 14Four; it&apos;s also about making a difference. We believe in the power of integrity, life-long learning, collaboration, and finding a way. These values guide our approach to every project, ensuring that we deliver work that is both thoughtful and intentional.
              <br /> <br />
              Today, 14Four continues to grow and expand, driven by its founder&apos;s original vision and the relentless pursuit of excellence. With a portfolio that includes developing websites and applications for universities and non-profits, the company&apos;s mission remains clear: to help businesses all around the world receive unique, creative tech solutions.
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
