import React from "react";
import HeaderOne from "layouts/sections/page-sections/page-headers/components/HeaderOne";
import Card from "@mui/material/Card";
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
function Home14() {
  return (
    <>
      <div>
        <div style={{ height: "40rem" }}>
          <HeaderOne />
        </div>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Information />
          <Featuring />
        </Card>
      </div>
    </>
  );
}

export default Home14;
